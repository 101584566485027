.popup {
  position: relative;
  background: #fff;
  border: 1px solid #dadada;
  font-family: 'Noto Sans';
  width: 200px;
  padding: 0px;
  border-radius: 10px;
  z-index: 20;
}

.popup:after,
.popup:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.popup:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

.popup:before {
  border-color: rgba(218, 218, 218, 0);
  border-top-color: #dadada;
  border-width: 11px;
  margin-left: -11px;
}

.popup-top {
  color: #fff;
  background: black;
  padding: 1px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.date {
  color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
}

.popup-bottom {
  gap: 8px;
  padding: 10px 5px;
  height: 110px;
  color: white;
  background: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

@mixin label {
  color: #000;
  text-align: right;
  width: 49px;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 9px;
}

.downgrade-score-label {
  @include label;
}

.total-articles-label {
  @include label;
}

@mixin number {
  text-align: left;
  width: 50px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.downgrade-score-blue {
  @include number;
  color: var(--primary-primary-2, #009de0);
}

.downgrade-score-grey {
  @include number;
  color: var(--primary-primary-2, #A7A7A7);
}

.total-articles {
  @include number;
  color: var(--primary-black, #000);
}

@mixin articles {
  text-align: left;
  font-size: 9px;
  font-style: normal;
  font-weight: 750;
  line-height: 10px;
  padding-left: 9px;
}

.positive-negative-articles {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: right;
}

.positive-articles {
  @include articles;
  color: var(--utility-success, #14853d);
}

.negative-articles {
  @include articles;
  color: var(--utility-error, #c53532);
}

.separator-line {
  border: 1px solid #dadada;
  height: 0;
  margin: 3px;
  display: inline-block;
}

.news-click {
  margin: -5px;
  color: #000;
  text-align: center;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 9px;
  padding-top: 3px;
}

.selected-day {
  display: inline-flex;
  font-size: 0.75rem;
  padding: 6px;
  align-items: center;
  gap: 8px;
  border-color: hsl(0, 0%, 80%);
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  border: 0;
  color: #FFFFFF;
  background-color: #2C6EF2;
  height: 25px;
  vertical-align: middle;
}

.deselect-button {
  font-weight: 800;
  font-size: medium;
  padding-bottom: 4px;
}

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: 'MMC Display Condensed';
  src:
    url('../fonts/MMCDisplayCd_W_Bd.woff2') format('woff2'),
    url('../fonts/MMCDisplayCd_W_Bd.woff') format('woff');
}

@font-face {
  font-family: 'MMC Display Condensed Regular';
  src:
    url('../fonts/MMCDisplayCd_W_Rg.woff2') format('woff2'),
    url('../fonts/MMCDisplayCd_W_Rg.woff') format('woff');
}

// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

@import './Buttons.scss';

iframe#webpack-dev-server-client-overlay {
  display: none;
}

// Input icons
.icon {
  &-user {
    background-image: url('../../public/icons/user.svg');
  }
  &-password {
    background-image: url('../../public/icons/lock.svg');
  }
  &-search {
    background-image: url('../../public/icons/search.svg');
  }
  &-searchOrig {
    background-image: url('../../public/icons/searchOrig.svg');
  }
  &-remove {
    background-image: url('../../public/icons/remove.svg');
  }
  &-download {
    background-image: url('../../public/icons/download.svg');
  }
  &-download-white {
    background-image: url('../../public/icons/download-white.svg');
  }
  &-link {
    background-image: url('../../public/icons/link.svg');
  }
}

// Styles
html {
  @apply bg-ow-lighter-grey;
}
body {
  @apply lg:bg-ow-lighter-grey !font-sans;
}

.highlight-checked {
  .nt-article-highlight {
    @apply bg-ow-highlight text-black;
  }
}

// hides cookie widget from being display by osano
.osano-cm-widget {
  display: none;
}

// .osano-cm-link {
//   display: none;
// }

// we do not collect cookies for advertising purposes
//  tell the osana cookie manager not to worry about those
// .osano-cm-view--type_consent .osano-cm-list-item:nth-child(2) {
//   display: none;
// }

// react-multi-carousel overrides
.react-multiple-carousel__arrow {
  background: rgba(255, 255, 255, 0.75);

  &--left {
    left: calc(2% + 1px);
  }
  &--right {
    right: calc(2% + 1px);
  }

  &::before {
    color: #000;
  }
  &:hover {
    background: #fff;
  }
}

.add-new-item-dropdown {
  .fss-multiple-list-item {
    display: none;
    &:first-of-type {
      display: block;
    }
  }
}

.portfolilo-add-new-item {
  .add-new-item-dropdown__control {
    margin-left: -0.5rem;
  }
  .add-new-item-dropdown__indicators {
    opacity: 0;
  }
  &:hover {
    .add-new-item-dropdown__control,
    .add-new-item-dropdown__placeholder {
      @apply text-ow-secondary;
    }
    .add-new-item-dropdown__indicators {
      opacity: 1;
    }
  }
}

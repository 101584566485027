.fss-company-name {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: 'Noto Sans', 'sans-serif';
  color: rgb(0, 0, 0);
}

.fss-company-name-out-of-portfolio {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: 'Noto Sans', 'sans-serif';
}

.fss-company-country {
  font-size: 12px;
  line-height: 16px;
  color: rgb(0, 0, 0);
  font-family: 'Noto Sans', 'sans-serif';
}

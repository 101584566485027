.highcharts-tooltip-container {
  width: 215px;
  height: 162px;
  z-index: 20 !important;

  svg {
    width: 215px;
    height: 162px;
  }
}

.highcharts-label.highcharts-tooltip.highcharts-color-undefined {
  pointer-events: none !important;
}

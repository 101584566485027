.fss-chart-button {
  @apply hidden lg:flex items-center justify-end bg-ow-lighter-grey px-2 py-2 text-sm hover:bg-gray-100 my-[1px];

  img {
    @apply w-4 h-4 mr-2;
  }
}

.fss-button-spacer {
  @apply bg-ow-light-grey h-[22px] w-[1px] mx-2 my-[8px];
}

.fss-portfolio-selector {
  $p: &;
  @apply flex items-center text-ow-light-blue hover:text-ow-light-blue border-b border-black pb-1.5 mr-6 no-underline hover:no-underline focus:no-underline;

  &__pill {
    @apply ml-1.5 text-xs border border-ow-light-blue rounded-full px-2 py-0.5;
  }

  &--selected {
    @apply text-white border-white focus:text-white hover:text-white;

    #{$p}__pill {
      @apply text-black bg-white border-white;
    }
  }
}
